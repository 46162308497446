//
//  Header for issue (iframe) pages: flipbook, pdf-es, pdf-ds
//

import React from 'react'
import {Link} from 'gatsby'
import logo from '../assets/corporate/Anzeit_Wortmarke_rgb_positiv_web.svg'

export default function Header() {
  return (
    <header className="bg-back">
      <div className="py-[0.7em]">
        <Link title="anzeit publishing" to="/">
          <img
            width={3.9612015019}
            height={1}
            alt="anzeit logo" src={logo} className="
            h-[1em]
            w-auto
            cursor-pointer
            mx-[auto]
          "/>
        </Link>
      </div>
    </header>
  )
}
