import React, { useContext, useState } from 'react'
import {motion, AnimatePresence} from "framer-motion"
import {ConsentContext} from './consentProvider'

/* eslint-disable jsx-a11y/click-events-have-key-events */

export default function ConsentPopup() {
  const { isConsentOpen, closeConsentSettings, setConsents, services } = useContext(ConsentContext)

  const [impressumExpanded, setImpressumExpanded] = useState(false)
  const toggleImpressum = () => setImpressumExpanded(!impressumExpanded)

  const onAck = () => {
    const consents = services.map(d => ({
      id: d.id,
      status: true,
    }))
    setConsents(consents)
    closeConsentSettings()
  }

  return (
    isConsentOpen &&
    <div className="fixed top-0 w-full h-full overflow-auto">
      <div className="
        fixed
        w-full
        h-full
        bg-[rgba(0, 0, 0, 0.5)]
        backdrop-blur
      "/>
      <div className="
        absolute
        overflow-auto
        w-[60vw]
        max-w-[800px]
        h-[auto]
        max-h-[80vh]
        translate-x-[-50%]
        left-[50%]
        translate-y-[-50%]
        top-[50%]
        px-[3em]
        py-[2.2em]
        bg-white
        shadow-[0_0_24px_rgba(0,0,0,0.8)]
        text-[10px]
      ">

        <h1 className="font-bold text-[2em] mb-[0.5em]">Privatsphären-Einstellungen</h1>
        <p className="text-[1.6em]">Wir verwenden Cookies, um den Betrieb der Webseite sicherzustellen und Ihnen eine optimale Erfahrung zu bieten. Für die Darstellung interaktiver Magazin-Inhalte nutzen wir externe Services. Dadurch entstehen Datenverbindungen zu den jeweiligen Service-Betreibern mit jeweils eigenen Datenschutzrichtlinien. Durch klicken auf „Einverstanden“ bestätigen Sie, dass Sie über 16 Jahre alt sind und wir Cookies setzen und Datenverbindungen zu den beschriebenen Services aufnehmen dürfen.</p>

        <p className="underline my-[1em] text-[1.6em]">
          <button
            className="impressum-button mr-[0.5em] underline"
            tabIndex="0"
            title="Impressum"
            aria-label="Impressum einblenden"
            onClick={toggleImpressum}
          >
            Impressum
          </button>
          { /* eslint-disable-next-line react/jsx-no-target-blank */ }
          <a title="Datenschutzerklärung" href="/datenschutz" target="_blank">
            Datenschutzerklärung
          </a>
            { /* <a title="Allgemeine Geschäftsbedingungen" href="/agb">Allgemeine Geschäftsbedingungen</a> */}
        </p>

        <AnimatePresence initial={false}>
          {
            impressumExpanded &&
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="overflow-hidden"
            >
              <p className="text-[1.6em]">
                Angaben gemäß § 5 TMG:
                anzeit publishing, Jennifer An, Eisenacher Str. 48, 10823 Berlin
                Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
                Jennifer An, Eisenacher Str. 48, 10823 Berlin
              </p>
            </motion.div>
          }
          </AnimatePresence>

        <h1 className="font-bold text-[2em] my-[0.5em]">Services</h1>
        <ol className="my-[1.6em]">
          {
            services.map((s, i) => (
              <Service key={i} i={i} {...s} />
            ))
          }
        </ol>

        <div className="text-center mt-[2em]">
          <button
            title="Einstellungen akzeptieren"
            aria-label="Einstellungen akzeptieren"
            onClick={onAck}
            className="
              inline-block
              text-white
              text-[1.6em]
              bg-[rgb(0,105,237)]
              px-[0.9em]
              py-[0.4em]
              rounded
              tracking-wide
            "
          >
            Einverstanden
          </button>
        </div>
      </div>
    </div>
  )
}

const Service = ({ i, name, type, description, address, links }) => {

  const [isExpanded, setExpanded] = useState(false)
  const toggleExpanded = () => setExpanded(!isExpanded)

  return (
    <li key={i} className="
      block
      border-[1px]
      border-[#CCC]
      rounded-[4px]
      p-[1em]
      mb-[1em]
    ">
      <button
        title="Service Details"
        aria-label="Service Details einblenden"
        tabIndex="0"
        onClick={toggleExpanded}
        className="block w-full "
      >
        <div className="flex items-center justify-between cursor-pointer">
          <div className="font-bold text-[1.6em]">{name}</div>
          <div className={
            'mt-[0.2em] text-[1.4em] inline-block tracking-wide ' +
            (
              type.id === 'essential' ? 'opacity-[50%]' : ''
            )
          }>
            {type.label}
          </div>
          <div className="w-[1.4em] mt-[0.3em] h-[1.4em] text-[1.4em]">
            <PlusMinusToggle isOpen={isExpanded} />
          </div>
        </div>
      </button>
      <AnimatePresence initial={false}>
        {
          isExpanded &&
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            aria-expanded={isExpanded.toString()}
            expanded={isExpanded.toString()}
            className="text-[1.6em] overflow-hidden"
          >
            <p className="my-[1em]">{description}</p>
            <p className="my-[1em]">{address}</p>
            <p className="my-[1em]">
              { links.map((link, k) => (
              <a
                tabIndex={isExpanded ? 0 : -1}
                key={i + '-' + k}
                title={link.label}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="underline mr-[0.5em] inline-block"
                >{link.label}</a>
              ))}
            </p>
          </motion.div>
        }
      </AnimatePresence>
    </li>
  )
}

function PlusMinusToggle({ isOpen }) {
  return (
    <div className="
      relative w-full h-full overflow-hidden
      text-[1em] leading-[1.1em]
      cursor-pointer
    ">
      {
        isOpen ?
        <div className="absolute w-full text-[1.3em] text-center">-</div> :
        <div className="absolute w-full text-[1.3em] text-center">+</div>
      }
    </div>
  )
}
