import React from 'react'
import Header from './header'
import Footer from './footer'
import ConsentProvider from './consentProvider'
import ConsentPopup from './consentPopup'

// element, props, ...
// props === element.props
export default function Layout({ element }) {
  // console.log('element', element)
  const layout = element.props.pageContext.layout // case iframe, see below
  const path = element.props.path

  return (
    <ConsentProvider>
      <div className={
        layout === 'iframe' ?
          'flex flex-col w-full h-full'
          :
          ''
        }
      >
      {
        !path || path === '/' ?
        ''
        :
        <Header />
      }
      {element}
      <Footer />
      <ConsentPopup/>
      </div>
    </ConsentProvider>
  )
}
