//
//  Notes on event handling on `<button />` elements:
//  - a `button` element receives synthetic click events for the enter/space key
//  - user events have an event.detail of 1, synthetic ones a detail of 0
//  - this seems to be an accessibitlity feature of the brwoser and is not
//    the case for other elements with `role="button"`! True for CH/FF, not SAF.
//
//  -> to omit an additional key-listener (and handling double events) and to
//     make use of the browser's default accessibility behaviour (space/enter
//     key) just use `button` elements wiht only a click-listener
//

/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useContext } from 'react'
import { useState } from "react"
import { motion, AnimatePresence } from 'framer-motion'
import { ConsentContext } from './consentProvider'

export default function Footer() {
  const { openConsentSettings } = useContext(ConsentContext)
  const [ showImpressum, setShowImpressum ] = useState(false)
  const toggleImpressum = () => setShowImpressum(!showImpressum)

  const openConsentSettingsAndClose = () => {
    setShowImpressum(false)
    openConsentSettings()
  }
  const openPrivacyAndClose = () => {
    setShowImpressum(false)
    window.open('/datenschutz' , '_blank')
  }

  return (
    <footer
      aria-expanded={showImpressum.toString()}
      className="
      text-center text-[12px] leading-[1.5]
      select-none
      bg-back
      "
    >
      <div className="
        relative
        h-[4em]
      ">
        <hr className="
          absolute
          top-[50%]
          left-[50%]
          translate-x-[-50%]
          translate-y-[-50%]
          w-[5em]
          border-t-width-[1px]
          border-t-black
          mt-[0.2em]
        "/>
        <button
          title="Impressum"
          aria-label="Impressum einblenden"
          tabIndex="0"
          onClick={toggleImpressum}
          className="
            absolute
            top-[50%]
            left-[50%]
            translate-x-[-50%]
            translate-y-[-50%]
            pt-[0.25em]
            px-[0.5em]
            bg-back
          "
        >
          §
        </button>
      </div>
      <div className="overflow-hidden">
        {
          <AnimatePresence initial={false}>
            {
              showImpressum &&
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
              >
                <p className="mx-[auto] pt-[0.5em] pb-[1.5em]">
                  Angaben gemäß § 5 TMG: <br />
                  anzeit publishing, Jennifer An, Eisenacher Str. 48, 10823 Berlin <br />
                  Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV: <br />
                  Jennifer An, Eisenacher Str. 48, 10823 Berlin <br />
                  <button
                    title="Privatsphären-Einstellungen"
                    aria-label="Privatsphären-Einstellungen einblenden"
                    tabIndex="0"
                    onClick={openConsentSettingsAndClose}
                    className="inline underline privacy-button"
                  >
                    Privatsphären-Einstellungen</button>
                  <br />
                  { /* eslint-disable-next-line react/jsx-no-target-blank */ }
                  <button
                    title="Datenschutzerklärung"
                    aria-label="Datenschutzerklärung öffnen"
                    className="inline underline"
                    tabIndex="0"
                    onClick={openPrivacyAndClose}
                  >
                    Datenschutzerklärung
                  </button>
                  <br />
                </p>
              </motion.div>
            }
          </AnimatePresence>
        }
      </div>
    </footer>
  )
}
