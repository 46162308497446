import React, {createContext, useState, useRef, useEffect} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Fsm from '../../lib/fsm'

export const ConsentContext = createContext({})

const isVersionInvalid = (localStorage, version) => {
  return localStorage.getItem('cm-version') !== version
}

const isStale = (localStorage, maxAgeDays) => {
  const consentDate = localStorage.getItem('cm-date') // cm-date example: 2020-01-24
  if (typeof consentDate !== 'string') return true
  if (consentDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
    const today = new Date()
    const msToDays = 1 / 1000 / 60 / 60 / 24
    const daysPassed = (today - new Date(consentDate)) * msToDays
    if (daysPassed > maxAgeDays) {
      return true
    }
  }
  return false
}

const clearConsents = localStorage => {
  Object.keys(localStorage).forEach(key => {
    if (key.match(/^cm-/)) localStorage.removeItem(key)
  })
}

const sanitizeStatus = statusRaw => {
  const statusAsString = String(statusRaw)
  if (statusAsString === 'true')
    return true
  if (statusAsString === 'false')
    return false
  return false
}

const readConsents = (localStorage, services, version, maxAgeDays) => {
  // version invalid
  if (isVersionInvalid(localStorage, version) || isStale(localStorage, maxAgeDays)) {
    clearConsents(localStorage)
    return services.map(s => ({
      id: s.id,
      status: false,
    }))
  }

  return services.map(s => {
    const status = sanitizeStatus(localStorage.getItem(`cm-${s.id}`)) // string
    return {
      id: s.id,
      status
    }
  })
}

const writeConsents = (localStorage, consents, version) => {
  consents.forEach(s => {
    localStorage.setItem(`cm-${s.id}`, s.status)
  })

  // update
  localStorage.setItem('cm-version', version)
  localStorage.setItem('cm-date', new Date().toISOString().slice(0, 10))
}

export default function ConsentProvider({children}) {
  const data = useStaticQuery(graphql`
    query {
      allDataServicesYaml {
        edges {
          node {
            yamlId
            name
            type {
              id
              label
            }
            description
            address
            links {
              label
              url
            }
          }
        }
      }
    }
  `)

  const services = data.allDataServicesYaml.edges.map(({node}) => {
    return Object.assign({}, node, {id: node.yamlId})
  })

  const version = '1' // string, because potentially semver
  const maxAgeDays = 365

  const [ allConsents, setAllConsents ] = useState()
  const [ isConsentOpen, setIsConsentPopup ] = useState(false)

  const setConsents = consents => {
    setAllConsents(consents)
    writeConsents(window.localStorage, consents, version)
  }

  const requireConsents = consents => {
    const consentRequired = consents.find(consent => {
      const match = allConsents.find(check => check.id === consent.id)
      if (!match || match.status === false) return true
      return false
    })

    if (consentRequired) openConsentSettings()
  }

  const openConsentSettings = () => {
    setIsConsentPopup(true)
  }

  const closeConsentSettings = () => {
    setIsConsentPopup(false)
  }

  // fsm
  const fsm = useRef(Fsm())

  // fsm: initial state
  const initial = useRef((e) => {
    switch (e) {
      case 'initialize':
        // read from persistence, if available
        let consents = readConsents(window.localStorage, services, version, maxAgeDays)
        setAllConsents(consents)
        fsm.current.tran(idle.current)
        break
      default:
        break
    }
  })

  // fsm: idle state
  const idle = useRef((e) => {})

  // start fsm
  fsm.current.init(initial.current)

  // on mount
  useEffect(() => {
    fsm.current.dispatch('initialize')
  }, [])

  const exposed = {
    allConsents,
    requireConsents,
    setConsents,
    isConsentOpen,
    openConsentSettings,
    closeConsentSettings,
    services,
  }

  return (
    <ConsentContext.Provider value={exposed}>
      { children }
    </ConsentContext.Provider>
  )
}
